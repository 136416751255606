import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { Context } from "../context"
import Header from "./Header"
// import Footer from "./Footer"
import Normalize from "./styles/Normalize"
import Reset from "./styles/Reset"

const lightTheme = {
  foreground: "#363631",
  // foreground: "#15b52c",
  background: "#f9f9f9",
}

const darkTheme = {
  foreground: "#ffffff",
  background: "#000000",
}

const theme = {
  // media queries
  desktoplarge: "1800px",
  desktop: "1280px",
  tablet: "1021px",
  mobile: "750px",
  mobilesmall: "325px",
}

const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Reset};

  *, *:before, *:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Favorit', sans-serif;
    font-size: 1.4rem;
    line-height: 1.2;
    text-transform: uppercase;

    @media (min-width: 1440px) {
      font-size: 1.7rem;
    }
  }

  a {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
  }

  a, a:visited, a:hover {
    text-decoration: none;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  .times {
    font-family: "TN", serif;
    text-transform: none;
    font-size: 1.8rem;
    letter-spacing: -0.05rem;
    line-height: 1;

    &.italic {
      font-style: italic;
    }

    @media (min-width: 1440px) {
      font-size: 2.1rem;
    }
  }
`

const Layout = ({ children }) => {
  const { style } = useContext(Context)
  return (
    <ThemeProvider
      theme={
        style === "light"
          ? { ...theme, ...lightTheme }
          : { ...theme, ...darkTheme }
      }
    >
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      {/* <Footer /> */}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
