import React from "react"
import styled from "styled-components"
import Logo from "./Logo"

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: ${({ theme }) => theme.foreground};
  transition: all 0.3s ease-in-out;

  .right {
    @media (min-width: ${({ theme }) => theme.mobile}) {
      text-align: right;
    }
  }

  a,
  a:visited,
  a:hover {
    color: ${({ theme }) => theme.foreground};
  }

  a:hover {
    font-style: italic;
  }
`
const Grid = styled.div`
  display: grid;
  padding: 2rem 3rem;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-rows: repeat(3, min-content);
    text-align: center;
    row-gap: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1440px) {
    padding: 2.5rem 3rem;
  }

  > svg {
    margin: -0.75rem auto 0;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      grid-row: 1 / span 1;
      margin-bottom: 1rem;
    }
  }
`

const Header = () => (
  <Container>
    <Grid>
      <ul>
        <li>
          <h1>Massif Wines</h1>
        </li>
        <li>Western Australian based</li>
        <li className="times italic">Wine Imports</li>
      </ul>
      <Logo />
      <ul className="right">
        <li>
          <a download href="/Massif_Portfolio_2022.pdf" target="_blank">
            Our Portfolio
          </a>
        </li>
        <li>
          Contact{" "}
          <a
            className="times"
            href="mailto:oliver@massifwines.com"
            target="_blank"
            rel="noreferrer"
          >
            General
          </a>
          ,{" "}
          <a
            className="times"
            href="mailto:sam@massifwines.com"
            target="_blank"
            rel="noreferrer"
          >
            Sales
          </a>
        </li>
      </ul>
    </Grid>
  </Container>
)

export default Header
